import React from 'react'
import cartImage from '../../images/cart.png'
import { useCartState } from '../context/CartContext'
import { Link } from 'gatsby'
import { chakra, Image } from '@chakra-ui/react'

const FloatingCartButton = () => {
  const { cart } = useCartState()

  return (
    <chakra.span
      pos='fixed'
      display='inline-block'
      right={10}
      bottom={10}
      backgroundColor='transparent'
      rounded='full'
    >
      <Link to={'/cart'}>
        <Image src={cartImage} alt='cart' width='50px' height='50px' />
        <chakra.span
          right='-1'
          top='-1'
          pos='absolute'
          px={2}
          py={2}
          fontSize='xs'
          fontWeight='bold'
          lineHeight='none'
          color='red.100'
          transform='translate(50%,-50%)'
          bg='red.600'
          rounded='full'
        >
          {cart?.contents.itemCount || 0}
        </chakra.span>
      </Link>
    </chakra.span>
  )
}

export default FloatingCartButton

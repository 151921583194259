import React from 'react'
import FloatingCartButton from './FloatingCartButton'
import Footer from './Footer'
import Header from './Header'
import SEO from './Seo'

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <SEO />
      <Header />
      <main>
        {children}
        <FloatingCartButton />
      </main>
      <Footer />
    </>
  )
}

export default Layout

import React from 'react'
import logo from '../../images/go4sledge-logo.gif'
import { Image, Button, Flex, Spacer, Center } from '@chakra-ui/react'

const Header = () => {
  return (
    <Center zIndex='2'>
      <Flex w='full' maxW='1600px'>
        <a href='/'>
          <Image
            src={logo}
            w='100px'
            m='1'
            letterSpacing={'-.1rem'}
            alt='Logo'
          />
        </a>
        <Spacer />
        <Button as='a' p='5' m='5' bgColor='black' color='white' href={'/cart'}>
          Cart
        </Button>
      </Flex>
    </Center>
  )
}

export default Header
